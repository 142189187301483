<template>
  <div>
    <div class="lds-dual-ring" v-if="loading"></div>
    <div v-if="error" class="error" id="login_error"><AlertCircleOutline/> Could not retrieve order</div>
    <div class="banner" v-if="!loading && !error">
        <div class="bannercontent">
          <div class="left">
            <div class="logo"></div>
            <div class="bannertitle">{{order.orderID}}</div>
            <div class="bannerdesc">{{order.date}}</div>
            <div class="tag" :class="order.status">{{orderStatus[order.status]}}</div>
            <button class="moreButton button" @click="getOrderInfo(false)"><ChevronDown/>Load brandconfig</button>
          </div>

          <div class="right">
            <div v-for="item in order.components" :key="item.serial" :class="item.type" v-bind:style="{ 'background-image': 'url('+getModelImg(item.model)+')', 'opacity' : (item.active ? '1' : '0.5')}">
              <div><div>{{item.type}}</div><br>{{item.serial}}<br>{{item.bdAddr}}</div>
            </div>
          </div>
        </div>
      </div>
    
    <div class="content"  v-if="!loading">

      <div v-if="order.brandconfig">
        <div class="title">Customer details</div>
        <b>Name: </b> {{ order.brandconfig.firstname }} {{ order.brandconfig.lastname }} ({{ order.brandconfig.initials }})<br>
        <b>Company: </b> {{ order.brandconfig.companyName }} <br>
        <b>Department: </b> {{ order.brandconfig.department }} <br>
        <br>
      </div>


      <div class="title">Flow details</div>
      <div v-for="(node,index) in order.flow.nodes" :key="node.key" style="display: inline-block;">
        
        <div class="exactList" v-if="combinedExactItems[index].length != 0">
          <div class="exactItem" v-for="exactItem in combinedExactItems[index]" :key="exactItem.exact_id">
            <input class="itemAmount" v-model="exactItem.amount"> {{ exactItem.unit }} -
            {{exactItem.description}}
          </div>
          </div>
        <div class="arrowExact" v-if="combinedExactItems[index].length != 0"><ArrowDownIcon/></div>

        <div class="node" :class="{ active: node.results.length }" v-if="!node.isPrinter">
          <b style="padding: 8px" v-if="node.station">{{ node.station.name }}</b>
          <div v-if="node.hasOwnProperty('testID')" class="nodeItem" :class="{ nodeItemActive: node.results.length }">{{ node.test.name }}</div>
          <div v-if="node.stationID == 'ENGI'" class="nodeItem" :class="{ nodeItemActive: node.results.length }">Engineering</div>
          <button class="button" @click="$router.push('/results?orderid='+order.orderID+'&stationid='+node.stationID)"><OpenInNew/> {{ node.results.length }} testresults</button>
        </div>
        
        <!-- Printer node -->
        <div class="node" :class="{ active: order.printDetails != null }" v-if="node.isPrinter">
          <b style="padding: 8px">3D Printing</b>
          <div class="nodeItem" v-if="order.printDetails != null" :class="{ nodeItemActive: order.printDetails != null }">{{ order.printDetails.printer.name }} ({{ order.printDetails.printer.serial }})</div>
          <div class="nodeItem" v-if="order.printDetails == null">No print info</div>
          <div class="graphBlock" v-if="order.printDetails != null">
          <div class="graphBlockPart" style="background-color: rgb(93, 149, 77)" :style="` width: ${order.printDetails.build.currentPercentage}%`">{{order.printDetails.build.currentPercentage}}%</div>
          </div>
          <div class="graphBlock" v-if="order.printDetails == null">
          <div class="graphBlockPart" :style="`width: 100%`">0%
          </div>
          </div>
        </div>
        
        <div v-if="index != (order.flow.nodes.length-1)" class="arrow"><ArrowRight/></div>
      </div>
      
      <div class="title">Audit log</div>
      <div class="auditLog">
        <div v-for="(logEntry,index) in order.auditLog" :key="index" class="auditEntry">
          <div class="auditTag">{{logEntry.user}}</div> {{logEntry.info}}
          <div style="font-size: 11px; font-weight: bold;">{{logEntry.date}}</div>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import AlertCircleOutline from 'vue-material-design-icons/AlertCircleOutline.vue'
import ArrowRight from 'vue-material-design-icons/ArrowRight.vue'
import OpenInNew from 'vue-material-design-icons/OpenInNew.vue'
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue'
import ArrowDownIcon from 'vue-material-design-icons/ArrowDown.vue'
export default {
  name: 'Order',
  components: {
    AlertCircleOutline,
    ArrowRight,
    OpenInNew,
    ChevronDown,
    ArrowDownIcon
  },
  data() {
    return{
      order: [],
      stations: [],
      loading: true,
      error: false,
      orderStatus:
      {
        "CREATED" : 'Created',
        "SLOT_ASSIGNED" : 'Slot Assigned',
        "SLOT_MISSED" : 'Slot Missed',
        "IN_PROGRESS" : 'In Progress',
        "COMPLETED" : 'Completed',
        "OBA_REQUIRED" : 'OBA Required',
        "CANCELED" : 'Canceled',
        "ONHOLD" : 'On Hold',
        "PRINTING" : 'Printing in progress',
        "PRINTING_COMPLETE" : 'Printing complete',
      },
      combinedExactItems: []
    }
  },
  mounted() {
     
      this.axios
      .get('/stations')
      .then(response => {
          this.stations = response.data;

          this.getOrderInfo(true);

      })
      .catch(err => {
          console.error(err);
          this.loading = false;
          this.error = true;
      });
  },
  methods: {
    getOrderInfo(skipExact)
    {
      this.loading = true;
      var route = '/order/'+this.$route.params.id
      if(skipExact === true)
      {
        route += '/skip';
      }
      this.axios
          .get(route)
          .then(response => {
              this.order = response.data;
              this.loading = false;
              
              for(let i = 0; i < this.order.flow.nodes.length; i++)
              {
                for(let x = 0; x < this.stations.length; x++)
                {
                  if (this.order.flow.nodes[i].stationID == this.stations[x].stationID)
                  {
                    this.order.flow.nodes[i].station = this.stations[x];
                    break;
                  }
                }

                this.combinedExactItems[i] = [];

                for(let y = 0; y < this.order.flow.nodes[i].results.length; y++)
                {

                  if(this.order.flow.nodes[i].results[y].exactItems != null)
                  {

                    for(let z = 0; z < this.order.flow.nodes[i].results[y].exactItems.length; z++)
                    {
                        let tmp = this.order.flow.nodes[i].results[y].exactItems[z];
                        let found = false;
          
                        for(let broer = 0; broer < this.combinedExactItems[i].length; broer++)
                        {
                          if(tmp.exact_id == this.combinedExactItems[i][broer].exact_id)
                          {
                            found = true;
                            this.combinedExactItems[i][broer].amount += tmp.amount;
                          }
                        }
                        if(found == false)
                        {
                          this.combinedExactItems[i].push(tmp);
                        }
                    }
                  }
                }
              }
              
              let left = false, right = false, cradle = false
              let shellL = false, shellR = false;
              for(let i = 0; i < this.order.components.length; i++)
              {
                  if (left == false && this.order.components[i].type == "LEFT")
                  {
                    this.order.components[i].active = true;
                    left = true;
                  }
                  if (right == false && this.order.components[i].type == "RIGHT")
                  {
                    this.order.components[i].active = true;
                    right = true;
                  }
                  if (cradle == false && this.order.components[i].type == "CRADLE")
                  {
                    this.order.components[i].active = true;
                    cradle = true;
                  }
                  if (shellL == false && this.order.components[i].type == "SHELL_LEFT")
                  {
                    this.order.components[i].active = true;
                    shellL = true;
                  }
                  if (shellR == false && this.order.components[i].type == "SHELL_RIGHT")
                  {
                    this.order.components[i].active = true;
                    shellR = true;
                  }
              }
              return true;
          })
          .catch(err => {
              console.error(err);
              return false;
          });
    },
    getModelImg(url) 
    {
      try {
        var images = require.context('../assets/models', false, /\.png$/)
        return images('./' + url + ".png")
      }
      catch(error)
      {
        if(url.includes("C_"))
        {
          return images('./unknown_cr.png')
        }
        if(url.includes("E_"))
        {
          return images('./unknown_eb.png')
        }
      }
    }
  }
}
</script>
<style scoped>
.itemAmount{
  display: inline-block;
  background-color: #6d6d6d;
  color: white;
  border-radius: 3px;
  font-weight: bold;
  margin-top: 0px;
  width: 25px;
  height: 6px;
  border-color: transparent;
  padding: 0;
  padding-top: 6px;
  padding-bottom: 6px;
  text-align: center;
}
.exactList{
  width: 200px;
  padding: 5px;
  margin-top: 10px;
  border-radius: 2px;
  margin: 0 auto;
  left: -15px;
  position: relative;
  border-style: dashed;
  border-width: 2px;
  border-color: #4d4d4d;
  margin-top: 10px;
}

.exactItem{
  display: inline-block;
  background-color: white;
  color: #575757;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  padding: 5px;
  margin-bottom: 2px;
}
.arrowExact{
  font-size: 30px;
  text-align: center;
  color: #5e5e5e;
  vertical-align: top;
  left: -15px;
  position: relative;
}
.auditLog{
  background-color: #fcfcfc;
  border-radius: 2px;
  padding: 5px;
  font-size: 11px;
  margin-top: 5px;
}
.auditEntry{
  display: block;
  border-bottom: #ececec;
  border-width: 1px;
  border-bottom-style: solid;
  padding: 4px;
  color: rgb(65, 65, 65);
}

.auditEntry:last-child {
  border-width: 0px;
}

.auditTag{
  border-color: #71bd84;
  border-width: 1px;
  border-style: solid;
  display: inline-block;
  padding: 2px;
  font-weight: bold;
  border-radius: 2px;
}

.banner{
  min-height: 160px;
  height: 100%;
}
.content{
  /* text-align: center; */
}
.arrow{
  height: 40px;
  font-size: 30px;
  text-align: center;
  color: #5e5e5e;
  display: inline-block;
  vertical-align: top;
  margin-top: 40px;
}
.node{
  background-color: #a6a6a6;
  color: white;
  min-width: 200px;
  height: 100px;
  border-radius: 3px;
  padding-top: 10px;
  display: inline-block;
  text-align: center;
  margin-top: 10px;
  opacity: 0.5;
}

.title
{
  font-weight: bold;
  font-size: 20px;
  color:#444444;
  margin-top: 5px;
}

.graphBlock{
  background-color: #4d4d4d;
  text-align: left;
  width: 100%;
  margin-top: 5px;
  height: 25px;
  white-space: nowrap;
}
.graphBlockPart{
  background-color: white;
  width: 0%;
  height: 25px;
  display: inline-block;
  text-align: center;
  line-height: 25px;
  color: white;
  font-weight: bold;
}

.active{
  background-color: #80bd71;
  opacity: 1;
}

.button{
  margin-top: 5px;
  background-color: transparent;
}
.button:hover{
  background-color: #67995b;
}

.moreButton{
  display: block;
  margin-top: 0px;
  background-color: #919191;
}
.moreButton:hover{
  background-color: #616161;
}

.nodeItem{
  background-color: #868686;
  padding: 5px;
  margin-top: 5px;
}
.nodeItemActive{
  background-color: #67995b;
}
.left{
  float: left;
}
.right{
  float: right;
  display: inline-block;
  padding-top: 25px;
}

.error{
  width: inherit;
  height: 500px;
  line-height: 500px;
  color: #5e5e5e;
  font-size: 25px;
}
.CRADLE{
  background-repeat: no-repeat;
  background-size:cover;
  background-position: center;
  height: 150px;
  width: 200px;
  display: inline-block;
  margin-right: 10px;
  background-position-y: -20px;
}
.CRADLE div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding-bottom: 3px;
}

.CRADLE div div{
  background-color: #80bd71;
  padding: 0px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
}

.LEFT{
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 180px;
  display: inline-block;
  margin-right: 10px;
  background-position-x: 20px;
  background-position-y: 20px;
}
.RIGHT{
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 180px;
  display: inline-block;
  margin-right: 10px;
  background-position-x: 20px;
  background-position-y: 20px;
}

.LEFT div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding-bottom: 3px;
}
.LEFT div div{
  background-color: #5f82ce;
  padding: 0px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
}

.RIGHT div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding-bottom: 3px;
}

.RIGHT div div{
  background-color: #ce5f5f;
  padding: 0px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
}

.SHELL_LEFT{
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 180px;
  display: inline-block;
  margin-right: 10px;
  background-position-x: 20px;
  background-position-y: 20px;
}
.SHELL_LEFT div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding-bottom: 3px;
}

.SHELL_LEFT div div{
  background-color: #5f82ce;
  padding: 0px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
}


.SHELL_RIGHT{
  background-repeat: no-repeat;
  background-size: cover;
  height: 150px;
  width: 180px;
  display: inline-block;
  margin-right: 10px;
  background-position-x: 20px;
  background-position-y: 20px;
}
.SHELL_RIGHT div{
  position: absolute;
  width: inherit;
  text-align: center;
  background-color: #9c9c9c;
  color: white;
  border-radius: 2px;
  padding-bottom: 3px;
}

.SHELL_RIGHT div div{
  background-color: #ce5f5f;
  padding: 0px;
  border-radius: 2px;
  font-weight: bold;
  font-size: 12px;
}

.FAIL{
  background-color: #bd7171;
}

.PASS {
  background-color: #67995b;
}

.BLUE{
  background-image: url('/assets/bamboo_cradle_black.png');
}

.RED{
  background-image: url('/assets/bamboo_cradle_red.png');
}

.BLACK{
  background-image: url('/assets/bamboo_cradle_black.png');
}

.CUSTOM{
  background-image: url('/assets/bamboo_cradle_custom.png');
}

.COMPLETED{
  background-color: #71bd84;
}

.INPROGRESS{
  background-color: #7971bd;
}

.SLOT_ASSIGNED{
  background-color: #df8764;
}

.SLOT_MISSED{
  background-color: #bd7171;
}

.OBA_REQUIRED{
  background-color: #d1ce1d;
}


</style>
