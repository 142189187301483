<template>
  <div>
      <div class="modal" id="modal" v-if="visible" v-on:click="close()">
      <div class="modal-content" v-on:click.stop>
        <button v-on:click="close()" class="rightbutton"> 
            <span class="buttonicon">
              <Close/>
            </span>
        </button>
         <div class="label">{{desc}}</div>

        <ag-grid-vue
        style="width: 1024px; margin-top: 10px; height: 500px"
        class="ag-theme-alpine"
        :columnDefs="columnDefs"
        :rowData="items"
        :pagination="true"
        @rowClicked="onRowClicked"
        >
        </ag-grid-vue>

      </div>
    </div>
  </div>
</template>

<script>
import Close from 'vue-material-design-icons/Close.vue'
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { AgGridVue } from "ag-grid-vue";
export default {
    name: "OrdersModal",
    components: {
      Close,
      AgGridVue
    },
    props: {
        desc: String,
        items: [],
        visible: Boolean,
        loading: Boolean,
        currentNode: Number
    },
    data(){
      return{
      }
    },
    methods: {
      close: function(){
        this.$emit('closed')
      },
      onRowClicked(params) {
        this.$emit('itemSelected', params.node.data, this.currentNode)
      }
    },
    beforeMount() {
  
      this.columnDefs = [
        { field: "item_code", sortable: true, filter: true, headerName: "Item code" },
        { field: "group_code", sortable: true, filter: true, headerName: "Group code" },
        { field: "stock", sortable: true, filter: true, headerName: "Current stock" },
        { field: "description", sortable: true, filter: true, headerName: "Description" }
      ];

    }
}
</script>

<style scoped>
.modal {
  position: fixed;
  z-index: 1; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgba(0,0,0,0.4); 
}
.modal-content {
  background-color: #7994a8;
  margin: 15% auto;
  border-radius: 2px;
  height: 500px;
  width: 1024px;
  padding-top: 10px;
}
.label{
    color: white;
    font-weight: bold;
    text-align: left;
    padding-left: 10px;
}
.item{
  display: block;
  height: 40px;
  line-height: 40px;
  background-color: #475663;
  color: white;
  border-top: 1px solid #333e47;
  cursor: pointer;
  padding-left: 10px;
}
.list{
  margin-top: 10px;
}
.rightbutton{
  float: right;
  margin-right: 5px;
  color: #ffff;
  background-color: transparent;
  min-width: 10px;
  min-height: 10px;
  border: none;
  border-radius: 2px;
  -webkit-transition: background-color 70ms linear;
  -moz-transition: background-color 70ms linear;
  -o-transition: background-color 70ms linear;
  transition: background-color 70ms linear;
  position: relative;
}

</style>